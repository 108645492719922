@import './loader';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.styled {
  line-height: 1.8rem;
  font-size: 1.3rem;

  a:-webkit-any-link:not(.header), a:not(.header) {
    border-bottom: 2px solid #dd6b20;
    color: #dd6b20;
  }

  a:-webkit-any-link:hover, a:hover {
    border-bottom-color: #0280FF;
    color: #0280FF;
  }

  & > p {
    margin-bottom: 1rem;
    // text-indent: 2rem;
  }

  & > ul p, & > li p {
    margin-bottom: 1rem;
  }

  img {
    margin: 30px 0 30px -2vw;
    height: auto;
    border-radius: 4px;
    width: calc(100% + 4vw);
    max-width: 100vw;
  }

  h1, h2, h3, h4, h5 {
    font-weight: bold;
    margin-bottom: 0.5rem;
    line-height: 110%;
    margin-top: 1.5rem;
  }

  // https://chakra-ui.com/theme
  h5 {
    font-size: 18px;
  }

  h4 {
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
  }

  h2 {
    font-size: 28px;
  }

  h1 {
    font-size: 36px;
  }

  ul {
    list-style-type: disc;
    padding: 0 3rem;
    margin-bottom: 2rem;
  }

  ol {
    padding: 0 3rem;
    margin-bottom: 2rem;
  }
}

.blue-gradient {
  background: rgb(0,101,215);
  background: linear-gradient(270deg, rgba(0,101,215,1) 0%, rgba(0,158,255,1) 100%);
}



/*===================================================================
		Water Animation
=====================================================================*/

.bg-water {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-water:before,
.bg-water:after {
  z-index: 0;
  content: "";
  position: absolute;
  left: 50%;
  min-width: 300vw;
  min-height: 300vw;
  background: linear-gradient(270deg, rgba(0,101,215,1) 0%, rgba(0,158,255,1) 100%);
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.bg-water:before {
  bottom: 6vh;
  border-radius: 45%;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
}
.bg-water:after {
  bottom: 4vh;
  opacity: .5;
  border-radius: 47%;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
}
.bg-water .apps-craft-welcome-screenshort.parallax {
	z-index: 1;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, 0) rotateZ(0deg);
            transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -2%) rotateZ(180deg);
            transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, 0%) rotateZ(360deg);
            transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, 0) rotateZ(0deg);
            transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -2%) rotateZ(180deg);
            transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, 0%) rotateZ(360deg);
            transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

